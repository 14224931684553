<!-- 
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0
-->

<!-- this SFC is responsible for adding the user into the waiting
room queue and displaying their position from the request -->

<template>
  <div class="d-flex flex-column border border-2 rounded p-2">
    <!-- display a header with the last connection status -->
    <div class="text-center lead mb-2">My Counter Number</div>
    <div class="mb-2">
      This compartment shows your assigned counter number while you wait to
      fetch your token and check out.
    </div>
    <div>
      <!-- display an alert based on the state -->
      <div
        v-if="!hasQueuePosition && !entryFailed"
        class="alert alert-primary"
        role="alert"
      >
        Retrieving your place in line
      </div>
      <div
        v-if="hasQueuePosition && !entryFailed"
        class="alert alert-success"
        role="alert"
      >
        Your counter number is {{ myPosition }}
      </div>
      <div v-if="entryFailed" class="alert alert-danger" role="alert">
        An error occurred while entering the line
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axiosClient from "../utils/axios";

export default {
  name: "MyPosition",
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["hasRequestId", "hasQueuePosition", "hasToken"]),
    // properties for vuex state
    requestId() {
      return this.$store.state.requestId;
    },
    myPosition() {
      return this.$store.state.myPosition;
    },
  },
  data() {
    return {
      entryFailed: false,
    };
  },
  mounted() {
    if (!this.hasRequestId) {
      this.requestQueuePosition();
    } else {
      this.retrieveLinePosition();
    }
    this.$watch("hasRequestId", this.retrieveLinePosition);
  },
  methods: {
    async requestQueuePosition() {
      const eventId = this.$store.state.eventId;
      if (!eventId) {
        this.$router.push({ name: "404" });
      }
      const resource = `${this.$store.state.publicApiUrl}/assign_queue_num`;
      const body = {
        event_id: eventId,
      };
      const store = this.$store;
      const component = this;
      try {
        const response = await axiosClient().post(resource, body);
        store.commit("setRequestId", response.data.api_request_id);
      } catch (error) {
        console.log(error);
        component.entryFailed = true;
      }
    },
    async retrieveLinePosition() {
      const eventId = this.$store.state.eventId;
      const requestId = this.$store.state.requestId;
      const resource = `${this.$store.state.publicApiUrl}/queue_num?event_id=${eventId}&request_id=${requestId}`;
      const store = this.$store;
      const component = this;
      try {
        const response = await axiosClient().get(resource);
        store.commit(
          "setMyPosition",
          Number.parseInt(response.data.queue_number)
        );
      } catch (error) {
        console.log(error);
        component.entryFailed = true;
      }
    },
  },
};
</script>
