<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    document.title = "OnTheList Waiting Room";
  },
  mounted() {
    const publicApiUrl = process.env.VUE_APP_PUBLIC_API_URL;
    if (publicApiUrl) {
      this.$store.commit("setPublicApiUrl", publicApiUrl);
    }
    if (document.location.search) {
      const parameters = new URLSearchParams(document.location.search);
      const eventId = parameters.get("eventId");
      if (eventId) {
        this.$store.commit("setEventId", eventId);
      }
    }
  },
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
</style>
