import axios from "axios";
import axiosRetry from "axios-retry";

const maxApiRetries = 5;

function axiosClient() {
  const client = axios.create({
    validateStatus: function (status) {
      return status === 200;
    },
  });
  // set the retry interceptor to retry until success
  axiosRetry(client, {
    retries: maxApiRetries,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: function (state) {
      return state.response.status !== 200;
    },
  });

  return client;
}

export default axiosClient;
