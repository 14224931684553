<template>
  <div class="vl-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { mixin as VueTimers } from "vue-timers";

import axiosClient from "../utils/axios";

const UPDATE_INTERVAL_MS = 1000;

export default {
  mixins: [VueTimers],
  timers: {
    retrieveLinePosition: {
      time: UPDATE_INTERVAL_MS,
      autostart: true,
      repeat: true,
      immediate: true,
    },
  },
  data() {
    return {
      isLoading: false,
      retryCount: 0,
      maxRetries: 10,
    };
  },
  components: {
    Loading,
  },
  async mounted() {
    this.isLoading = true;
    this.retryCount = 0; // Reset retry count
    this.requestQueuePosition();
    if (!this.timers.retrieveLinePosition.isRunning) {
      this.$timer.start("retrieveLinePosition");
    }
  },
  unmounted() {
    if (this.timers.retrieveLinePosition.isRunning) {
      this.$timer.stop("retrieveLinePosition");
    }
  },
  methods: {
    async requestQueuePosition() {
      const eventId = this.$store.state.eventId;
      if (!eventId) {
        this.$router.push({ name: "404" });
      }
      const resource = `${this.$store.state.publicApiUrl}/assign_queue_num`;
      const body = {
        event_id: eventId,
      };
      const store = this.$store;
      const component = this;
      try {
        const response = await axiosClient().post(resource, body);
        store.commit("setRequestId", response.data.api_request_id);
      } catch (error) {
        console.log(error);
        component.entryFailed = true;
      }
    },
    async retrieveLinePosition() {
      if (this.retryCount >= this.maxRetries) {
        console.log("Max retry attempts reached. Stopping timer.");
        this.$timer.stop("retrieveLinePosition");
        this.$router.push({ name: "404" });
        return;
      }
      this.retryCount++;

      const eventId = this.$store.state.eventId;
      const requestId = this.$store.state.requestId;
      if (!eventId || !requestId) return;
      const resource = `${this.$store.state.publicApiUrl}/queue_num?event_id=${eventId}&request_id=${requestId}`;
      const store = this.$store;
      const component = this;
      try {
        const response = await axiosClient().get(resource);
        store.commit(
          "setMyPosition",
          Number.parseInt(response.data.queue_number)
        );
        if (response.data.within_capacity) {
          await this.retrieveTokenAndRedirect();
        } else if (response.data.within_capacity === false) {
          this.$router.push({ name: "Waiting Room" });
        }
      } catch (error) {
        console.log(error);
        component.entryFailed = true;
      }
    },
    async retrieveTokenAndRedirect() {
      const resource = `${this.$store.state.publicApiUrl}/generate_token`;
      const body = {
        event_id: this.$store.state.eventId,
        request_id: this.$store.state.requestId,
      };
      const store = this.$store;
      const component = this;
      try {
        const response = await axiosClient().post(resource, body);
        store.commit("setToken", response.data.access_token);
        this.$timer.stop("retrieveLinePosition");
        const redirectUrl = `https://${response.data.destination_url}?token=${response.data.access_token}`;
        window.location.href = redirectUrl;
      } catch (error) {
        console.log(error);
        component.tokenRetrievalFailed = true;
      }
    },
  },
};
</script>
