<template>
  <div class="d-flex flex-column w-50 mx-auto border border-2 rounded mt-2">
    <div class="text-center lead mb-2">Waiting Room</div>
    <div class="text-center mb-2">
      You will wait here until the serving counter advances past your assigned
      counter number.
    </div>
    <div class="d-flex flex-row">
      <MyPosition class="w-50 m-2" />
      <ServingPosition class="w-50 m-2" />
    </div>
    <div class="d-flex flex-row">
      <WaitingRoomSize class="w-50 m-2" />
      <div class="w-50 m-2" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MyPosition from "@/components/MyPosition.vue";
import ServingPosition from "@/components/ServingPosition.vue";
import WaitingRoomSize from "@/components/WaitingRoomSize.vue";
import axiosClient from "@/utils/axios";

export default {
  name: "WaitingRoom",
  created() {
    this.$watch("readyForCheckOut", this.passThruCheckOut);
  },
  methods: {
    passThruCheckOut() {
      console.log("readyForCheckOut:", this.readyForCheckOut);
      if (this.readyForCheckOut) {
        this.retrieveTokenAndRedirect();
      }
    },
    navigateToCheckOut() {
      this.$router.push("/checkout");
    },
    async retrieveTokenAndRedirect() {
      const resource = `${this.$store.state.publicApiUrl}/generate_token`;
      const body = {
        event_id: this.$store.state.eventId,
        request_id: this.$store.state.requestId,
      };
      const store = this.$store;
      const component = this;
      try {
        const response = await axiosClient().post(resource, body);
        store.commit("setToken", response.data.access_token);
        const redirectUrl = `https://${response.data.destination_url}?token=${response.data.access_token})`;
        window.location.href = redirectUrl;
      } catch (error) {
        console.log(error);
        component.tokenRetrievalFailed = true;
      }
    },
  },
  computed: {
    ...mapGetters(["hasRequestId", "hasQueuePosition", "hasToken"]),
    myPosition() {
      return this.$store.state.myPosition;
    },
    queuePosition() {
      return this.$store.state.queuePosition;
    },
    readyForCheckOut() {
      return (
        this.hasRequestId &&
        this.myPosition &&
        this.queuePosition &&
        this.myPosition <= this.queuePosition
      );
    },
  },
  components: {
    MyPosition,
    ServingPosition,
    WaitingRoomSize,
  },
};
</script>
